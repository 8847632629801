import { default as ClinicUBepewwCtnMeta } from "/temp/build/src/pages/Clinic.vue?macro=true";
import { default as ContactsZlEQZEXvUnMeta } from "/temp/build/src/pages/Contacts.vue?macro=true";
import { default as CookiesPolicyfvK2BI6P84Meta } from "/temp/build/src/pages/CookiesPolicy.vue?macro=true";
import { default as DoctorsDoFVZqUZoTMeta } from "/temp/build/src/pages/Doctors.vue?macro=true";
import { default as indexoDnGPG6m87Meta } from "/temp/build/src/pages/index.vue?macro=true";
import { default as LicencesX8ZvLsorSaMeta } from "/temp/build/src/pages/Licences.vue?macro=true";
import { default as NeurologistXi7zIAUJBEMeta } from "/temp/build/src/pages/Neurologist.vue?macro=true";
import { default as PersonalDataUZGIe6DdnbMeta } from "/temp/build/src/pages/PersonalData.vue?macro=true";
import { default as PromotionsIoAumL0AbLMeta } from "/temp/build/src/pages/Promotions.vue?macro=true";
import { default as ReviewsEctiAI2h11Meta } from "/temp/build/src/pages/Reviews.vue?macro=true";
import { default as ServicesYm3nDb40xsMeta } from "/temp/build/src/pages/Services.vue?macro=true";
import { default as VacancieshmvxTSMZmuMeta } from "/temp/build/src/pages/Vacancies.vue?macro=true";
import { default as Vacancy4lVJ0611pNMeta } from "/temp/build/src/pages/Vacancy.vue?macro=true";
import { default as DoctorsXPPoRG2VymMeta } from "~/pages/Doctors.vue?macro=true";
export default [
  {
    name: "ClinicPage",
    path: "/kontakty/:clinic",
    meta: ClinicUBepewwCtnMeta || {},
    component: () => import("/temp/build/src/pages/Clinic.vue")
  },
  {
    name: "Contacts",
    path: "/kontakty",
    meta: ContactsZlEQZEXvUnMeta || {},
    component: () => import("/temp/build/src/pages/Contacts.vue")
  },
  {
    name: "CookiesPolicy",
    path: "/cookie-policy",
    meta: CookiesPolicyfvK2BI6P84Meta || {},
    component: () => import("/temp/build/src/pages/CookiesPolicy.vue")
  },
  {
    name: "Doctors",
    path: "/Doctors",
    meta: DoctorsDoFVZqUZoTMeta || {},
    component: () => import("/temp/build/src/pages/Doctors.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexoDnGPG6m87Meta || {},
    component: () => import("/temp/build/src/pages/index.vue")
  },
  {
    name: "Licences",
    path: "/kontakty/licenziya",
    meta: LicencesX8ZvLsorSaMeta || {},
    component: () => import("/temp/build/src/pages/Licences.vue")
  },
  {
    name: "Neurologist",
    path: "/vrachi/nevrolog/:slug*",
    meta: NeurologistXi7zIAUJBEMeta || {},
    component: () => import("/temp/build/src/pages/Neurologist.vue")
  },
  {
    name: "PersonalData",
    path: "/personal-data-policy",
    meta: PersonalDataUZGIe6DdnbMeta || {},
    component: () => import("/temp/build/src/pages/PersonalData.vue")
  },
  {
    name: "Promotions",
    path: "/akczii",
    meta: PromotionsIoAumL0AbLMeta || {},
    component: () => import("/temp/build/src/pages/Promotions.vue")
  },
  {
    name: "Reviews",
    path: "/otzyvy",
    meta: ReviewsEctiAI2h11Meta || {},
    component: () => import("/temp/build/src/pages/Reviews.vue")
  },
  {
    name: "ServicesPage",
    path: "/uslugi",
    meta: ServicesYm3nDb40xsMeta || {},
    component: () => import("/temp/build/src/pages/Services.vue")
  },
  {
    name: "Vacancies",
    path: "/vakansii",
    meta: VacancieshmvxTSMZmuMeta || {},
    component: () => import("/temp/build/src/pages/Vacancies.vue")
  },
  {
    name: "Vacancy",
    path: "/vakansii/:vacancy",
    meta: Vacancy4lVJ0611pNMeta || {},
    component: () => import("/temp/build/src/pages/Vacancy.vue")
  },
  {
    name: "Doctors",
    path: "/vrachi/:slug*",
    meta: DoctorsXPPoRG2VymMeta || {},
    component: () => import("~/pages/Doctors.vue")
  },
  {
    name: "uslugi",
    path: "/uslugi/:slug+",
    component: () => import("~/pages/Doctors.vue")
  },
  {
    name: "Disease",
    path: "/zabolevaniya/:slug*",
    component: () => import("~/pages/Doctors.vue")
  }
]