import type SearchResultInterface from '../../interfaces/SearchResultInterface';

import { postToServer } from '../../util/UseFetchToServer';
import { API_MODX_URL } from '../../config';

type TSearchResponse = {
    data:SearchResultInterface[]
};

class SearchService {
  public async searchFetch(searchString:string = '', sessionId:string = '') {
    const { data } = await postToServer(API_MODX_URL, { component: 'health', action: 'search', searchString, sessionId }) as TSearchResponse;
    return data;
  }
}

export default new SearchService();
