<script setup lang="ts">
  import '@egjs/vue3-flicking/dist/flicking.css';
  const pageInfoStore = usePageInfoStore();
  const pageInfo = computed(() => pageInfoStore.pageInfo);
  // WatchEffect to keep seoMeta in sync with pageInfo
  useSeoMeta({
    title: () => pageInfoStore.pageInfo?.longtitle + ' - Ист Клиник' ?? 'Ист Клиник',
    ogTitle: () => pageInfoStore.pageInfo?.title,
    description: () => pageInfo.value?.description,
    ogDescription: () => pageInfo.value?.description
  });
 </script>

<template>
  <div>
    <NuxtLoadingIndicator color="#2a76e8" :height="4" :throttle="0" />
    <NuxtLayout>
      <div>
        <NuxtPage class="global-wrapper" />
      </div>
    </NuxtLayout>
    <ClientOnly>
      <CookieBanner />
    </ClientOnly>
  </div>
</template>

<style>
.layout-enter-active,
.layout-leave-active,
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
  /* transition: all 0.6s; */
}
.layout-enter-active,
.layout-leave-active,
.page-enter-from,
.page-leave-to {
  opacity: 0.33;
}
</style>
