import { useClinicsStore } from '~/stores/ClinicsStore';
import { PlaceLocationsService, SearchService } from '../EastclinicVueApi';

export default defineNuxtPlugin(async (nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, _instance, info) => {
    if (error) {
      showError({
        statusCode: 500,
        message: info
      });
    }
  };
  await useClinicsStore().fetchClinics()
  await PlaceLocationsService.getPlacesFromServer();
  await SearchService.searchFetch();
});
