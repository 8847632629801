import { useClinicsStore } from '~/stores/ClinicsStore';
import type { IPageInfoRequest } from '../EastclinicVueApi';

export default defineNuxtRouteMiddleware(async (to, from) => {
  // skip middleware on initial client load
  const nuxtApp = useNuxtApp();
  // if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
  //   return;
  // }

  const pageInfoStore = usePageInfoStore();
  const sessionId = pageInfoStore.sessionId;
  const request = <IPageInfoRequest>{ resourceUrl: to.path, previousUrl: from.path };
  if (!sessionId && to.path === from.path) {
    request.startSession = true;
  }
  await pageInfoStore.fetch(request);
  // await useAsyncData('pageInfo', () => pageInfoStore.fetch(request));

  // after fetch to server, in pageInfoService global contains reactive page info data
  useClinicsStore().setCurrentClinic(pageInfoStore.currentClinicId as number)
});
